<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Поставки

        vuecrud(selectedtable = "wb_incomes", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")

</template>

<script>

export default {
  name: 'Partner_Incomes',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
        }
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.delete('id');
      cols.delete('partner');
      cols.delete('number');

      cols.set('incomeId', {label: "Номер поставки", hasFilter: false, });
      cols.set('date', {label: "Дата", hasFilter: false, });
      cols.set('lastChangeDate', {label: "Обновлено", hasFilter: false, });
      cols.set('supplierArticle', {label: "Ваш артикул", hasFilter: false, });
      cols.set('techSize', {label: "Размер", hasFilter: false, });
      cols.set('barcode', {label: "Баркод", hasFilter: false, });
      cols.set('quantity', {label: "Остатки", hasFilter: false, });
      cols.set('totalPrice', {label: "Цена из УПД", hasFilter: false, });
      cols.set('dateClose', {label: "Дата принятия (закрытия)", hasFilter: false, });
      cols.set('warehouseName', {label: "Склад", hasFilter: false, });
      cols.set('nmId', {label: "ID товара", hasFilter: false, });
      cols.set('status', {label: "Cтатус поставки", hasFilter: false, });

      return cols;

    },
  }
}
</script>
